.table-po-samsung-special {
  @apply hidden lg:table;

  * {
    @apply text-xs xl:text-base;
  }

  td {
    @apply p-1.5 xl:p-2 2xl:p-[15px];
  }
}

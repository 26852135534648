input:-internal-autofill-selected {
  // @apply bg-transparent #{!important};
}

.drawer .ant-drawer-header-title {
  display: flex;
  flex-direction: row-reverse;
  button {
    margin: 0;
  }
}

.ant-modal-title {
  span {
    @apply text-lg;
  }
}

.ant-modal-content {
  @apply p-3 md:p-4 lg:p-6 #{!important};
}

.modal-no-space > .ant-modal-content {
  @apply p-0 #{!important};
}

.modal-no-space .ant-modal-header {
  @apply m-0 #{!important};
}

.modal-reminder-coverage .ant-modal-content {
  @apply rounded-[43px] #{!important};
}

.modal-transparent .ant-modal-content {
  @apply rounded-[43px] bg-transparent shadow-none #{!important};
}

.ant-modal-close {
  @apply h-[30px] w-[30px] flex items-center justify-center #{!important};
  background-color: transparent;
  &:focus,
  &:active,
  &:hover {
    background-color: transparent !important;
  }
}

.ant-input {
  @apply min-h-[44px] px-[10px] py-[10px] border border-gray-300 shadow-sm placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1;
}

.ant-input-wrapper {
  @apply h-[44px];
  input {
    @apply px-[10px] py-[10px] border shadow-sm border-gray-300 placeholder-[#bbbbbb] text-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 block w-full rounded-md sm:text-sm focus:ring-1;
  }
}

.ant-input-affix-wrapper {
  @apply h-[44px] px-[10px] py-[10px] shadow-none border-gray-300;
  .ant-input {
  }
  input {
    @apply shadow-none h-auto min-h-0;
  }

  &-readonly {
    input {
      @apply cursor-default;
    }
  }
}

.ant-input-password {
  @apply p-0 shadow-sm border-gray-300 overflow-hidden;
  input {
    @apply px-[10px] py-[10px] shadow-none placeholder-[#bbbbbb] overflow-hidden #{!important};
    box-shadow: transparent !important;
  }
  .ant-input-suffix {
    @apply px-2;
  }
}

.ant-input-number-wrapper {
  @apply px-[10px] py-[10px];
  .ant-input-number {
    input {
      @apply px-[10px] py-[10px];
    }
    &.ant-input-number-focused {
      input {
        @apply outline-none ring-0;
      }
    }
  }
}

.search-input .ant-input-search-button:not(.ant-btn-primary):hover,
.search-input .ant-input,
.search-input .ant-btn {
  @apply text-gray-500 outline-none ring-0 border-gray-300 focus:border-theme-primary-main #{!important};
}

// .ant-select {
//   @apply focus:ring-sky-500 focus:ring-1 rounded-md #{!important};
//   .ant-select-selection-search-input {
//     @apply h-full #{!important};
//   }
//   .ant-select-selector {
//     @apply border-gray-300 rounded-md h-auto min-h-[44px] #{!important};
//     .ant-select-selection-search {
//       @apply ring-transparent w-full;
//       input {
//         @apply ring-transparent text-left w-full #{!important};
//         // @apply pt-[8px] #{!important};
//       }
//     }
//     .ant-select-selection-placeholder {
//       @apply mt-[5px];
//     }
//   }
//   &.ant-select-multiple {
//     .ant-select-selector {
//       @apply border-gray-300 rounded-md min-h-[44px] #{!important};
//       .ant-select-selection-search {
//         @apply pl-0 left-0;
//         @apply ring-transparent w-full #{!important};
//         input {
//           @apply left-0 pl-0;
//           @apply ring-transparent text-left  #{!important};
//         }
//       }
//       .ant-select-selection-placeholder {
//         @apply mt-[0px];
//       }
//       .ant-select-selection-overflow-item {
//         // @apply w-full #{!important};
//       }
//     }
//   }
//   &.ant-tree-select {
//     .ant-select-selection-item {
//       @apply mt-[5px];
//     }
//   }
//   .ant-select-selection-item {
//     @apply mt-[5px];
//   }
// }

.ant-select {
  @apply focus:ring-sky-500 focus:ring-1 rounded-md #{!important};
  .ant-select-selection-search-input {
    @apply h-full #{!important};
  }
  .ant-select-selector {
    @apply border-gray-300 rounded-md h-auto min-h-[44px] flex items-center #{!important};

    .ant-select-selection-placeholder {
      // @apply m-0 #{!important};
      @apply align-middle #{!important};
    }
    .ant-select-selection-search {
      @apply ring-transparent w-full;
      input {
        @apply ring-transparent text-left w-full #{!important};
        // @apply pt-[8px] #{!important};
      }
    }
  }
  // &.ant-tree-select {
  //   .ant-select-selection-item {
  //     @apply mt-[5px];
  //   }
  // }
  .ant-select-selection-item {
  }
}

.ant-select.rounded-right-none {
  @apply rounded-r-none #{!important};
  .ant-select-selector {
    @apply rounded-r-none  #{!important};
  }
  &.ant-select-multiple {
    .ant-select-selector {
      @apply rounded-r-none #{!important};
    }
  }
}

.ant-picker {
  @apply py-[10px];
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  @apply ml-[1px] mr-[10px] -order-1 #{!important};
}

button.ant-btn:not([disabled]) {
  &.ant-btn-primary {
    @apply bg-theme-primary-main text-white #{!important};
    &hover {
      @apply bg-theme-primary-main text-white #{!important};
    }
  }
}

.ant-picker-panel-layout .ant-picker-footer .ant-picker-now .ant-picker-now-btn:hover {
  @apply underline underline-offset-2 #{!important};
}

.ant-picker-panel-layout .ant-picker-ok button.ant-btn:not([disabled]) {
  @apply bg-blue-600 text-white #{!important};
}

.ant-picker-panel-layout .ant-picker-ok button.ant-btn:not([disabled]):hover {
  @apply bg-blue-600 text-white #{!important};
}

.ant-btn.button {
  @apply border rounded-lg outline-none shadow-none border-theme-primary-main px-8 flex items-center justify-center border-transparent;
  &.ant-btn-link {
    @apply px-0 py-0 mx-0 #{!important};
  }
  box-sizing: border-box;
  &:disabled {
    opacity: 0.6;
  }
  // span {
  //   @apply break-words;
  //   @apply text-xs;
  //   @screen sm {
  //     @apply text-base;
  //   }
  // }

  &:hover {
  }

  &.size-middle {
  }

  &.size-small {
  }

  &.size-large {
  }

  &.variant-default {
    @apply p-0 text-theme-primary-main border-none #{!important};
    &:hover {
      @apply outline-none border-none;
    }
    &:focus {
      @apply border-none outline-none;
    }
    &:after {
      all: unset;
    }
  }

  &.variant-primary {
    // @apply bg-gradient-to-r;
    @apply bg-gradient-to-r from-theme-primary-main to-theme-primary-lighter text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-primary-without-gradient {
    @apply bg-theme-primary-main text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-primary-gradient {
    @apply bg-gradient-to-r from-theme-primary-main to-theme-primary-lighter text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-primary-solid {
    @apply bg-theme-primary-main text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-info {
    @apply bg-blueInfo text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-warning {
    @apply bg-orangeWarning text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-danger {
    @apply bg-red-500 text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-outlined {
    @apply border-theme-primary-main #{!important};
    span {
      @apply text-theme-primary-main;
    }
    svg {
      @apply fill-theme-primary-main;
    }
    &:hover:not(:disabled) {
      @apply bg-theme-primary-main;
      span {
        @apply text-white;
      }
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-outlined-no-hover {
    @apply border border-theme-primary-main #{!important};
    span {
      @apply text-theme-primary-main;
    }
    svg {
      @apply fill-theme-primary-main;
    }
  }

  &.variant-outlined-info {
    @apply border border-blueInfo #{!important};
    span {
      @apply text-blueInfo;
    }
    &:hover:not(:disabled) {
      @apply border-blueInfo bg-blueInfo text-white #{!important};
      span {
        @apply text-white;
      }
    }
  }

  &.variant-success {
    @apply bg-greenSuccess text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-info {
    @apply bg-blueInfo text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-dashed {
    @apply border border-dashed border-theme-primary-main bg-theme-primary-main/[0.1] text-theme-primary-main;
  }

  &.variant-text {
    @apply p-0 mx-1 text-theme-primary-main border-none #{!important};
    &:hover {
      @apply outline-none border-none;
    }
    &:focus {
      @apply border-none outline-none;
    }
    &:after {
      all: unset;
    }
  }

  &:disabled {
  }
}

.ant-tag.tag {
  &.variant-primary {
    // @apply bg-gradient-to-r;
    @apply bg-theme-primary-main from-theme-primary-main to-theme-primary-lighter text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-primary-gradient {
    @apply bg-gradient-to-r from-theme-primary-main to-theme-primary-lighter text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-primary-solid {
    @apply bg-theme-primary-main text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-info {
    @apply bg-blueInfo text-white border-none;
    svg {
      @apply fill-white;
    }
    &:hover {
      @apply border-transparent;
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-warning {
    @apply bg-orangeWarning text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-danger {
    @apply bg-red-500 text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-outlined {
    @apply border-theme-primary-main #{!important};
    span {
      @apply text-theme-primary-main;
    }
    svg {
      @apply fill-theme-primary-main;
    }
    &:hover:not(:disabled) {
      @apply bg-theme-primary-main;
      span {
        @apply text-white;
      }
      svg {
        @apply fill-white;
      }
    }
  }

  &.variant-outlined-no-hover {
    @apply border border-theme-primary-main #{!important};
    span {
      @apply text-theme-primary-main;
    }
    svg {
      @apply fill-theme-primary-main;
    }
  }

  &.variant-outlined-info {
    @apply border border-blueInfo #{!important};
    span {
      @apply text-blueInfo;
    }
    &:hover:not(:disabled) {
      @apply border-blueInfo bg-blueInfo text-white #{!important};
      span {
        @apply text-white;
      }
    }
  }

  &.variant-success {
    @apply bg-greenSuccess text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-info {
    @apply bg-blueInfo text-white border-none;
    &:hover {
      @apply border-transparent;
    }
  }

  &.variant-dashed {
    @apply border border-dashed border-theme-primary-main bg-theme-primary-main/[0.1] text-theme-primary-main;
  }

  &.variant-text {
    @apply p-0 mx-1 text-theme-primary-main border-none #{!important};
    &:hover {
      @apply outline-none border-none;
    }
    &:focus {
      @apply border-none outline-none;
    }
    &:after {
      all: unset;
    }
  }

  &:disabled {
  }
}

.ant-card {
  @apply rounded-2xl px-5 py-3 shadow-lg;

  .ant-card-head {
    @apply px-0;
  }
  .ant-card-body {
    @apply px-0;
  }

  &.no-padding {
    @apply p-0;
    .ant-card-body {
      @apply p-0;
    }
  }

  &.no-shadow {
    @apply shadow-none #{!important};
  }
  &.no-border {
    @apply border-transparent #{!important};
  }
}

.ant-pagination {
  @apply flex justify-end relative;
  li.ant-pagination-item {
    @apply flex items-center justify-center;
    @apply border border-grayScale03 rounded-lg;
    a {
      @apply text-gray-500;
    }
    &.ant-pagination-item-active {
      a {
        @apply text-white #{!important};
      }
      @apply bg-theme-primary-main border-theme-primary-main #{!important};
    }
  }
  .ant-pagination-options {
    @apply absolute left-0;
  }
}

.ant-tabs {
  .ant-tabs-nav-wrap {
    .ant-tabs-tab {
      @apply bg-grayBackground px-6 py-2 m-0 rounded-t-xl text-gray-900;
      &.ant-tabs-tab-active {
        @apply bg-theme-primary-main;
        div {
          @apply text-white #{!important};
        }
      }
    }
  }
  .ant-tabs-nav-operations {
  }
  .ant-tabs-ink-bar {
    @apply hidden;
  }
}

.ant-switch {
  .ant-switch-inner {
    @apply bg-gray-400;
  }
  &.ant-switch-checked {
    .ant-switch-inner {
      @apply bg-greenSuccess;
    }
  }
}

.ant-collapse {
  .ant-collapse-item:not(.custom-collapse-item) {
    @apply rounded-none #{!important};
  }
  .ampdd-collapse-po-item .ant-collapse-content {
    @apply border-t-[#EEEEEE] #{!important};
  }
  &.no-border {
    @apply border-transparent;
  }
  &.no-shadow {
  }
  &.no-background {
    @apply bg-transparent #{!important};
    .ant-collapse-item {
      @apply bg-transparent #{!important};
    }
    .ant-collapse-header {
      @apply bg-transparent #{!important};
    }
    .ant-collapse-content {
      @apply bg-transparent;
    }
  }
}

.ant-table-thead .ant-table-cell {
  background-color: #dbdbdb !important;

  &:before {
    width: 0px !important;
  }
}

.ant-table-expanded-row .ant-table-cell {
  background-color: white !important;
}

.ant-table-row-level-1 .ant-checkbox-wrapper,
.ant-table-row-level-2 .ant-checkbox-wrapper {
  visibility: hidden !important;
}

.empty-expanded-row .ant-table-cell {
  padding: 0 !important;
  border: 0px !important;
}

.ant-form-vertical {
  .ant-form-item-label > label {
    height: 32px !important;
  }

  .custom-form-title-label label {
    width: 100% !important;
  }
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  position: absolute !important;
  top: 9px !important;
  right: -15px !important;
  font-size: 12px !important;
  font-family: 'Poppins' !important;
}

.ant-form-item-has-error {
  .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
  .ant-upload-select,
  .quill-text-editor {
    border-color: #ff4d4f !important;
  }
}

.ant-input-search {
  .ant-input-group-addon {
    button {
      height: 100%;
      @apply px-5 w-auto;
      @apply flex items-center;
    }
  }
}

.upload-full-parent-height-width .ant-upload-select {
  @apply w-full h-full m-0 #{!important};
}

.upload-product-section-banner .ant-upload-select {
  @apply w-[163px] h-[247px] m-0 rounded-2xl #{!important};
}

.custom-radio .ant-radio-checked .ant-radio-inner {
  border-color: red;
  background-color: red;
}

.custom-radio .ant-radio:hover .ant-radio-inner {
  border-color: red;
}

.custom-radio .ant-radio-checked::after {
  border: 1px solid red;
}

.custom-radio .ant-radio-input:hover {
  border-color: red;
}


.customize-upload .ant-upload {
  // border-color: #ff4d4f !important;
  border: none !important;
}

@import 'tailwindcss/base';

@layer base {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg font-semibold;
  }
  // a {
  //   @apply text-blue-600 underline;
  // }
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.printable-document-content {
  @apply text-grayScale05;

  .title {
    @apply font-bold;
    font-size: 14px !important;
  }

  * {
    font-size: 12px;
  }

  .label {
    @apply text-grayScale04;
  }
}

p {
  @apply text-sm;

  @screen md {
    font-size: 1rem;
  }
}

img {
  &:selection {
    display: none !important;
  }
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

​ html,
body {
  height: 100%;
  width: 100%;
}

a:hover {
  color: inherit;
}

.section-container {
  @apply relative container mx-auto p-4;
}

.section-container-hero-callpro {
  @apply relative container xl:max-w-[1352px] mx-auto p-4 md:py-[34px];
}

@layer components {
  .bg-red-gradient {
    @apply bg-gradient-to-r from-theme-primary-main to-theme-primary-lighter;
  }

  .bg-gray-gradient {
    @apply bg-gradient-to-r from-grayGradient01 to-grayGradient02;
  }

  .text-link {
    @apply text-blueInfo underline-offset-2 cursor-pointer;
  }
}

/*
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

input {
  @apply placeholder-[#bbbbbb] text-sm #{!important};
  &:placeholder {
    @apply text-xs #{!important};
  }
}

@import './extends-styles/simplebar-styles.scss';
@import 'react-day-picker/dist/style.css';
@import './extends-styles/react-date-picker-styles.scss';
@import './extends-styles/slick-styles.scss';
@import './extends-styles/antd-styles.scss';
@import './extends-styles/mantine-styles.scss';
@import './extends-styles/quill-style.scss';
@import './extends-styles/po-samsung-special.scss';

ul.order-status-timeline {
  @apply relative flex flex-col gap-3;
  li.order-status-timeline__item {
    padding-left: 20px;
    @apply relative;
    &:before {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 6px;
      bottom: 0;
      @apply bg-grayScale03 rounded-full;
      &:first-child {
        @apply bg-theme-primary-main rounded-full;
      }
    }
    &:not(:last-child) {
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        @apply bg-grayScale03;
        position: absolute;
        left: 5px;
        top: 18px;
        bottom: 0;
      }
    }
  }
}

ul.confirm-payment-status-timeline {
  @apply relative flex flex-col gap-3;
  li.confirm-payment-status-timeline__item {
    padding-left: 30px;
    @apply relative;
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      position: absolute;
      left: -1px;
      top: 2px;
      bottom: 0;
      @apply bg-grayScale03 rounded-full;
    }
    &:not(:last-child) {
      &:after {
        content: '';
        height: 100%;
        width: 1px;
        @apply bg-grayScale03;
        position: absolute;
        left: 9px;
        top: 18px;
        bottom: 0;
      }
    }

    &:last-child {
      &:before {
        @apply bg-[#00A925];
      }
    }
  }
}

.category-tree {
  margin-left: -5px;
  .ant-tree-node-content-wrapper {
    @apply rounded-lg px-2 #{!important};
    &.ant-tree-node-selected {
      @apply bg-theme-primary-main text-white #{!important};
    }
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: none !important;
  }
}

.region-area-tree {
  .ant-tree-node-content-wrapper {
    @apply rounded-lg px-2 #{!important};
    &.ant-tree-node-selected {
      @apply bg-theme-primary-main text-white #{!important};
    }
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
    background: none !important;
  }
  .ant-tree-switcher {
    display: none !important;
  }
}

.ant-form-item-label {
  @apply p-0 #{!important};
  label {
    @apply font-semibold text-sm #{!important};
  }
}

.radio-card {
  @apply flex flex-wrap gap-2 #{!important};
  .radio-card-item-wrapper {
    @apply bg-grayScale02 px-5 py-3;
  }

  .ant-radio-wrapper {
    @apply rounded-lg overflow-hidden m-0;
    border: 2px solid #ccc;
    span {
      @apply p-0;
    }
  }

  .ant-radio-wrapper-checked {
    @apply border-2 border-solid border-theme-primary-main;
    .radio-card-item-wrapper {
      @apply bg-theme-primary-main bg-opacity-80 text-white;
    }
  }

  .ant-radio {
    position: absolute;
    left: -9999px;
    overflow: hidden;
  }

  .ant-radio + * {
    display: block;
    padding: 0;
    overflow: hidden;
    @apply rounded-md;
  }
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

input[type='number'].no-wheel::-webkit-outer-spin-button,
input[type='number'].no-wheel::-webkit-inner-spin-button,
.no-wheel input[type='number']::-webkit-outer-spin-button,
.no-wheel input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.upload-dragger-wrapper {
  &.max-count {
    .ant-upload-drag {
      display: none !important;
    }
  }
}

form div.ant-form-item-explain .ant-form-item-explain-error:not(:first-child) {
  display: none;
}

.ant-form-item-explain-error {
  font-size: 13px;
}

img.ant-image-preview-img {
  display: inline-block;
}

.markdown-content {
  h1 {
    @apply font-bold mb-3;
    font-size: 20px;
  }
  h3 {
    @apply font-bold mb-2;
    font-size: 18px;
  }
  p {
    @apply mb-3;
  }
  ul {
    list-style: initial;
    @apply pl-6 mb-3;
  }
  ol {
    list-style: decimal;
    @apply pl-6 mb-3;
  }
}

article {
  h1,
  h2 {
    margin-bottom: 0.25em;
  }
  p {
    margin-bottom: 0.25em;
    line-height: 1.5em;
  }
  li {
    margin-bottom: 0.25em;
  }
}

.table-hover-row {
  table tbody tr:hover {
    @apply brightness-95 #{!important};
  }
}

.react-pdf__Page.loadingPage {
  display: none;
}

.scrollbar-always-visible::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollbar-always-visible::-webkit-scrollbar:vertical {
  width: 10px;
}

.scrollbar-always-visible::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scrollbar-always-visible::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.scrollbar-always-visible::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ddd;
}

.mantine-Checkbox-icon {
  display: none;
}

.mantine-Checkbox-inner {
  width: unset;
  height: unset;
}

.mantine-Container-root {
  @apply max-w-screen-xl;
}

div.mantine-AppShell-root * {
  @apply font-sans;
}

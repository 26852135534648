// @apply h-full #{!important};
div.quill-text-editor {
  @apply border border-transparent rounded-b-lg #{!important};
}

.ql-editor {
  @apply p-0 #{!important};
  p {
    @apply mb-1;
    @apply text-sm md:text-base;
  }
  a {
    @apply underline text-blue-600;
  }
  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }
  ul, ol {
    @apply pl-4 #{!important};

    li {
      @apply pl-0 #{!important};
    }
  }
  .ql-lineHeight-1-0 {
    line-height: 1;
  }
  .ql-lineHeight-1-15 {
    line-height: 1.15;
  }
  .ql-lineHeight-1-5 {
    line-height: 1.5;
  }
  .ql-lineHeight-2-0 {
    line-height: 2;
  }
  a {
    &.action-button {
      &.variant-primary {
        @apply no-underline;
        @apply bg-theme-primary-main text-white inline-block p-3 rounded-lg;
      }
    }
  }

  .seo-text {
    h1,
    h2,
    h3 {
      @apply text-[13px] leading-5;

      color: rgba(82, 88, 103) !important;
    }

    p {
      @apply text-xs text-justify m-0;

      strong,
      span {
        color: rgba(82, 88, 103, 0.80) !important;
      }
    }
  }
}

.quill {
  white-space: pre-wrap;
  @apply rounded-lg #{!important};
  .ql-toolbar {
    @apply rounded-t-lg;
  }
  .ql-container {
    @apply p-3;
    @apply rounded-b-lg h-[620px] font-sans #{!important};
  }
  .blot-formatter__overlay {
    .blot-formatter__toolbar {
      .blot-formatter__toolbar-button {
        position: relative !important;
        svg {
          position: absolute !important;
          top: 0;
          left: 0;
        }
      }
    }
  }
}

.quill-product-collection .ql-container {
  @apply h-[285px] max-w-[1500px] #{!important};
}

article {
  @apply h-auto;
}

.ql-tooltip {
  @apply z-[999];
}

.slick-dots.slick-thumb {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  bottom: -95px !important;
}

.slick-dots.slick-thumb ul {
  display: flex;
  flex-direction: row;
}

.slick-dots.slick-thumb li {
  overflow: hidden;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}

.slick-dots.slick-thumb li:hover,
.slick-dots.slick-thumb li:focus {
  border: 2px solid #e41b1b;
}

.slick-arrow.slick-disabled {
  display: none !important;
}

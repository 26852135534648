/* React Date Picker Custom Style */
.rdp {
  padding: 15px;
  background: white;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 20;
}

/* .rdp-button {
  background-color: #E41B1B;
} */

.rdp-day_selected:not([aria-disabled='true']),
.rdp-day_selected:focus:not([aria-disabled='true']),
.rdp-day_selected:active:not([aria-disabled='true']),
.rdp-day_selected:hover:not([aria-disabled='true']) {
  background-color: #e41b1b !important;
  border-color: transparent !important;
}
